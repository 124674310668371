import request from '@/utils/request'

// eslint-disable-next-line import/prefer-default-export
export function GetUniversities() {
  return request({
    url: '/api/University/GetUniversities/',
    method: 'get',
  })
}

export function GetUniversityById(data) {
  return request({
    url: `/api/University/GetUniversityById/${data}`,
    method: 'get',
  })
}
export function addUniversity(data) {
  return request({
    url: '/api/University/AddUniversity',
    method: 'post',
    data,
  })
}

export function updateUniversity(data) {
  return request({
    url: '/api/University/UpdateUniversity',
    method: 'put',
    data,
  })
}

export function deleteUniversity(data) {
  return request({
    url: `/api/University/DeleteUniversity?universityId=${data}`,
    method: 'delete',
  })
}
